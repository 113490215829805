// external
import {
  AppBar,
  Box,
  Button,
  Paper,
  Skeleton,
  Toolbar,
  Typography,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import { areArraysEqual } from 'utils';

// components
import { PageHeader, PageWrapper } from 'components';
import ProgramManageGuideMobilePage from '../ProgramManageGuideMobilePage';

// forms
import ProgramManageGuideForm from 'forms/ProgramManageGuideForm';

// store
import { useAppDispatch } from 'store/hooks';
import { hideNavbar, showAppAlert, showNavbar } from 'store/slices/appSlice';

// hooks
import {
  useCustomFields,
  useLocalization,
  useMemberships,
  useMobileMediaQuery,
  useProfiles,
  useRelationships,
} from 'hooks';

// types
import {
  EProgramVariation,
  ICustomField,
  IMembership,
  ISkill,
} from '@guider-global/shared-types';
import { IMembershipForm } from 'store/slices/formsSlice';

import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useSanityOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { interpolate } from 'functions';
import { ArrowBack } from '@mui/icons-material';

export interface BaseReactHookFormComponentProps {
  control: Control;
  errors: FieldErrors;
}

export type ProgramManageGuidePageRole = 'guide' | 'trainee';

export const ProgramManageGuidePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { palette, transitions } = useTheme();

  useEffect(() => {
    dispatch(hideNavbar(true));

    return () => {
      dispatch(showNavbar(true));
    };
  }, [dispatch]);

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const isMobile = useMobileMediaQuery();
  const {
    memberships,
    hasResultsMemberships,
    reqMemberships,
    isLoadingMemberships,
  } = useMemberships({
    getSilently: true,
  });
  const { reqCustomFields, isLoadingCustomFields } = useCustomFields({});

  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const discardChangesButtonLabel =
    baseLanguage?.globals?.common?.discard_changes_button_label;

  const { getOrganization } = useSanityOrganization({
    getSilently: true,
    organizationSlug: organizationSlug,
    localeCode: 'en_GB',
  });

  const organization = getOrganization();

  const { getProfiles } = useProfiles({ getSilently: true });
  const [profile] = getProfiles();
  const profileId = profile?.id ?? '';

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: ProgramManageGuidePageRole;
  }>();
  const isGuide = role === 'guide';

  const {
    relationships: getRelationships,
    isLoadingRelationships,
    reqRelationships,
  } = useRelationships({});
  const relationships = getRelationships();
  const relationship = relationships?.find(
    (relationship) =>
      !relationship.isConcluded &&
      relationship.programSlug === programSlug &&
      relationship.profileId === profileId,
  );

  const guideMembership = useMemo(
    () =>
      memberships().find(
        (membership) =>
          membership.role === 'guide' && membership.programSlug === programSlug,
      ),
    [memberships, programSlug],
  );
  const guideMembershipId = guideMembership?.id;

  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  const skillsConfig = useMemo(() => program?.registration?.skills, [program]);

  const skillsOptions = useMemo(
    () => skillsConfig?.options?.map((skill) => skill.id.current) ?? [],
    [skillsConfig],
  );

  const customGoalCategories =
    program?.registration?.goals?.custom_goal_categories;

  const selectedGoalSource =
    customGoalCategories && customGoalCategories.length > 0
      ? customGoalCategories
      : organization?.goal_categories?.categories;

  const goalCategories = selectedGoalSource.map((goalCategory) => ({
    label: goalCategory.goal_category_name,
    value: goalCategory.goal_category_slug?.current,
  }));

  const membershipProgramFields = useMemo(
    () => (guideMembership?.programFields as ICustomField[]) || [],
    [guideMembership],
  );

  const defaultFormValues = useMemo(() => {
    const programSkills = (guideMembership?.skills as Partial<ISkill>[]) ?? [];
    const initialGoalCategories = guideMembership?.goalCategories?.map(
      (goalCategory) => goalCategory.fieldSlug,
    );

    return {
      goalCategorySlugs: initialGoalCategories,
      title: relationship?.title ?? '',
      description: relationship?.description ?? '',
      ...(programSkills.length !== 0 && {
        skills: programSkills
          .map((skill) => skill.fieldSlug)
          .filter(
            (skillValue) => skillValue && skillsOptions.includes(skillValue),
          ),
      }),
      ...Object.fromEntries(
        membershipProgramFields.map((customField) => [
          customField.fieldSlug,
          customField.value,
        ]),
      ),
    } as FieldValues;
  }, [
    guideMembership?.skills,
    guideMembership?.goalCategories,
    relationship?.description,
    relationship?.title,
    skillsOptions,
    membershipProgramFields,
  ]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (relationship) {
      reset(defaultFormValues);
    }
  }, [relationship, defaultFormValues, reset]);

  const isMissingGuideMembership = hasResultsMemberships() && !guideMembership;
  useEffect(() => {
    if (isMissingGuideMembership) {
      navigate(`/programs/${programSlug}`);
    }
  }, [isMissingGuideMembership, navigate, programSlug]);

  useEffect(() => {
    if (!isGuide) {
      navigate(`/programs/${programSlug}`);
    }
  }, [isGuide, navigate, programSlug]);

  if (isMissingGuideMembership) {
    return <></>;
  }

  if (!program || !role) {
    return <></>;
  }

  const registrationQuestions =
    program?.registration?.registration_questions?.filter(
      (registrationQuestion) =>
        [role, 'both'].includes(registrationQuestion.audience),
    );

  const isGroupProgram =
    program.program_details?.program_variation === EProgramVariation.Group;
  const programType = program.program_details?.program_type;
  const programName = program.metadata.program_name;

  const programTypeText =
    program?.program_details?.program_type?.program_type_text;
  const programRegistrationGuide =
    programTypeText?.variations?.individual?.registration?.registration_guide;
  const membershipPending =
    programRegistrationGuide?.registration_guide_membership_pending;
  const guideSingular = programTypeText?.common?.guide?.singular;
  const traineeSingular = programTypeText?.common?.trainee?.singular;

  const onSubmit: SubmitHandler<IMembershipForm> = async (data) => {
    if (!guideMembershipId) {
      console.error('Failed to submit. No `guideMembershipId` provided.');
      return;
    }

    let newCustomFieldIds: string[] = [];

    const { isPublished, ...restData } = data;

    const {
      title,
      description,
      skills,
      goalCategorySlugs,
      ...newCustomFields
    } = restData;

    if (Object.keys(newCustomFields).length !== 0) {
      let customFieldsPostData: ICustomField[] = [];

      await Promise.all(
        Object.entries(newCustomFields).map(
          async ([newCustomFieldSlug, newCustomFieldValue]) => {
            const oldMembershipProgramField = membershipProgramFields.find(
              (membershipProgramField) =>
                membershipProgramField.fieldSlug === newCustomFieldSlug,
            );
            const oldValue = oldMembershipProgramField?.value;

            const isNew = !oldValue;

            if (!isNew) {
              let hasValueChanged = false;

              const fieldId = oldMembershipProgramField.id;
              const fieldType = oldMembershipProgramField.fieldType;

              const newValue = newCustomFieldValue;

              if (fieldType === 'multi-select') {
                if (Array.isArray(newValue) && Array.isArray(oldValue)) {
                  hasValueChanged = !areArraysEqual(newValue, oldValue);
                }
              } else {
                hasValueChanged = oldValue !== newValue;
              }

              if (!hasValueChanged) {
                return;
              }

              return await reqCustomFields({
                method: 'PATCH',
                url: `/customfields/${fieldId}`,
                data: {
                  value: newValue,
                  fieldType,
                },
              });
            } else {
              const currentRegistrationQuestion = registrationQuestions?.find(
                (registrationQuestion) =>
                  registrationQuestion.id.current === newCustomFieldSlug,
              );

              return customFieldsPostData.push({
                fieldSlug: newCustomFieldSlug,
                organizationSlug,
                fieldType: currentRegistrationQuestion?.type,
                value: newCustomFieldValue,
                profileId,
                programSlug,
              } as ICustomField);
            }
          },
        ),
      );

      if (customFieldsPostData?.length !== 0) {
        const { data: customFieldsResponseData } = await reqCustomFields({
          method: 'POST',
          url: '/customfields',
          data: customFieldsPostData,
        });

        if (customFieldsResponseData) {
          newCustomFieldIds = customFieldsResponseData.map(
            (customField) => customField.id,
          );
        }

        await reqCustomFields({ url: '/customfields' });
      }
    }

    let newRelationshipId: string | undefined;

    if (isGroupProgram && isGuide) {
      if (!relationship) {
        const { data: relationships } = await reqRelationships({
          method: 'POST',
          url: '/relationships',
          // TODO Fix Types
          // @ts-ignore
          data: {
            guideProfiles: [profileId],
            isConcluded: false,
            programSlug,
            organizationSlug,
            programTypeSlug: programType?.metadata?.id?.current,
            programVariationTypeSlug: EProgramVariation.Group,
            profileId,
            title,
            description,
          },
        });

        if (relationships) {
          const [newRelationship] = relationships;
          newRelationshipId = newRelationship.id;
        }
      } else {
        await reqRelationships({
          method: 'PATCH',
          url: `/relationships/${relationship.id}`,
          data: {
            title,
            description,
          },
        });
      }
    }

    const updateMembershipData: Partial<IMembership> & {
      goalCategorySlugs: string[];
      skillSlugs: string[];
    } = {
      role: 'guide',
      goalCategorySlugs: goalCategorySlugs as string[],
      isPublished: !relationship && isGroupProgram ? true : !!isPublished,
      programSlug,
      skillSlugs: data.skills ?? [],
      ...(newCustomFieldIds && {
        programFields: [
          ...membershipProgramFields.map((field) => field.id),
          ...newCustomFieldIds,
        ],
      }),
    };

    await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${guideMembershipId}`,
      data: updateMembershipData,
    });

    await Promise.all([
      reqMemberships({ url: '/memberships' }),
      reqRelationships({ url: '/relationships' }),
    ]);

    if (isGroupProgram && isGuide) {
      // created new group
      if (newRelationshipId) {
        navigate(`/relationships/${newRelationshipId}`);
      }
      // updated existing group
      else if (relationship) {
        navigate(`/relationships/${relationship.id}`);
      }
    } else {
      dispatch(
        showAppAlert({
          severity: 'success',
          message: 'Profile updated successfully',
          timeout: 5000,
        }),
      );

      navigate('/dashboard');
    }
  };

  // Base language Derivations
  const {
    title,
    subtitle,
    profile: sanityBaseLanguageProgramsProfile,
    profile_published_boolean_label: profilePublishedBooleanLabel,
    profile_published_boolean_description: profilePublishedBooleanDescription,
    update_profile_button_label: updateProfileButtonLabel,
  } = baseLanguage?.programs?.manage_guide_profile ?? {};
  const profileTypeHeading = isGuide
    ? `${guideSingular} ${sanityBaseLanguageProgramsProfile}`
    : `${traineeSingular} ${sanityBaseLanguageProgramsProfile}`;

  const pageSubtitle = interpolate(
    subtitle ?? '',
    {
      program_name: programName ?? '',
    },
    'Manage your profile',
  );
  if (isMobile)
    return (
      <ProgramManageGuideMobilePage
        goalCategories={goalCategories}
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        program={program}
        handleSubmit={handleSubmit}
        loading={
          isLoadingCustomFields() ||
          isLoadingMemberships() ||
          isLoadingRelationships()
        }
        guideMembership={guideMembership}
        isValid={isValid}
        isDirty={isDirty}
        role={role}
        profilePublishHeading={profilePublishedBooleanLabel}
        profilePublishDescription={profilePublishedBooleanDescription}
        discardChangesButtonLabel={discardChangesButtonLabel}
        updateProfileButtonLabel={updateProfileButtonLabel}
        profilePendingHeading={membershipPending?.title ?? ''}
        profilePendingDescription={membershipPending?.description ?? ''}
        title={title}
        pageSubtitle={pageSubtitle}
        programName={programName}
        profileTypeHeading={profileTypeHeading}
      />
    );

  return (
    <>
      <AppBar
        elevation={isMobile && scrollTrigger ? 3 : 0}
        sx={{
          backgroundColor: isMobile && scrollTrigger ? 'white' : 'transparent',
          transition: `all ${transitions.duration.standard}ms ${transitions.easing.easeInOut}`,
        }}
      >
        <Toolbar>
          <Button
            data-cy="pages_ProgramManageGuide_back-button"
            variant="text"
            startIcon={<ArrowBack />}
            fullWidth={false}
            disabled={isLoadingRelationships()}
            onClick={() => navigate(-1)}
            sx={{
              color: palette.default.text.primary,
            }}
          >
            {baseLanguage?.globals?.common?.go_back_button_label}
          </Button>
        </Toolbar>
      </AppBar>
      <PageHeader header={title} subheader={pageSubtitle} />
      <PageWrapper
        sx={{
          py: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column nowrap',
            width: '70%',
            maxWidth: '1440px',
          }}
        >
          <Paper
            elevation={0}
            sx={(theme) => ({
              py: 4,
              px: 3,
              borderTop: `8px solid ${theme.palette.secondary.main}`,
            })}
          >
            <Typography variant="h5">{programName}</Typography>
            <Typography variant="body1" sx={{ mb: 3 }} color="text.secondary">
              {profileTypeHeading}
            </Typography>
            {guideMembership ? (
              <ProgramManageGuideForm
                goalCategories={goalCategories}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                program={program}
                handleSubmit={handleSubmit}
                loading={
                  isLoadingCustomFields() ||
                  isLoadingMemberships() ||
                  isLoadingRelationships()
                }
                guideMembership={guideMembership}
                isValid={isValid}
                isDirty={isDirty}
                role={role}
                profilePublishHeading={profilePublishedBooleanLabel}
                profilePublishDescription={profilePublishedBooleanDescription}
                discardChangesButtonLabel={discardChangesButtonLabel}
                updateProfileButtonLabel={updateProfileButtonLabel}
                profilePendingHeading={membershipPending?.title ?? ''}
                profilePendingDescription={membershipPending?.description ?? ''}
              />
            ) : (
              <Skeleton />
            )}
          </Paper>
        </Box>
      </PageWrapper>
    </>
  );
};
